import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setLandlord } from "../../redux/slices/landlordswitcher";
import { useNavigate } from "react-router-dom";

import workspacelogo from "../../assets/img/logos/onw.webp";
import britishlandlogo from "../../assets/img/logos/storey.png";
import myologo from "../../assets/img/logos/myo.png";
import akoyalogo from "../../assets/img/logos/akoya.png";
import peckhamlevelslogo from "../../assets/img/logos/peckhamlevels.png";
import hoxtonworkspaceslogo from "../../assets/img/logos/hoxtonworkspaces.png";
import canvasofficeslogo from "../../assets/img/logos/canvas-transparent.png";
import gwslogo from "../../assets/img/logos/gws.png";
import squareapelogo from "../../assets/img/logos/squareape.png";
import braintreedistrictcouncillogo from "../../assets/img/logos/braintreedistrictcouncil.png";
import wavenetlogo from "../../assets/img/wavenet-connected-monogram-dark.png";
import gpelogo from "../../assets/img/logos/GPE.webp";
import storexlogo from "../../assets/img/logos/thestorexlogo.png";
import sohoworkslogo from "../../assets/img/logos/sohoworkslogo.png";
import arterialgrouplogo from "../../assets/img/logos/arterial-group-logo.jpeg";
import ascendlogo from "../../assets/img/logos/ascend-logo.png";
import gherkinlogo from "../../assets/img/logos/gherkin-logo.png";

const landlordOptions = {
  wsg: {
    name: "Workspace Group",
    icon: workspacelogo,
    landlordid: 2,
    ctids: [2, 4, 29, 30, 63, 64],
  },
  bl: {
    name: "British Land / Storey",
    icon: britishlandlogo,
    landlordid: 19,
    ctids: [35, 39, 41, 42],
  },
  myo: {
    name: "MYO",
    icon: myologo,
    landlordid: 20,
    ctids: [47, 48],
  },
  gws: {
    name: "Great Western Studios",
    icon: gwslogo,
    landlordid: 3,
    ctids: [8, 67],
  },
  gpe: {
    name: "Great Portland Estates",
    icon: gpelogo,
    landlordid: 34,
    ctids: [66, 75],
  },
  cnv: {
    name: "Canvas Offices",
    icon: canvasofficeslogo,
    landlordid: 24,
    ctids: [57],
  },
  akoya: {
    name: "Mapp/Akoya",
    icon: akoyalogo,
    landlordid: 13,
    ctids: [34, 68],
  },
  pl: {
    name: "Peckham Levels",
    icon: peckhamlevelslogo,
    landlordid: 16,
    ctids: [40, 72],
  },
  hxtn: {
    name: "Hoxton Workspaces",
    icon: hoxtonworkspaceslogo,
    landlordid: 18,
    ctids: [46, 73],
  },
  ape: {
    name: "Square APE",
    icon: squareapelogo,
    landlordid: 27,
    ctids: [56, 70],
  },
  bdc: {
    name: "Braintree District Council",
    icon: braintreedistrictcouncillogo,
    landlordid: 35,
    ctids: [76, 77],
  },
  strx: {
    name: "Store X",
    icon: storexlogo,
    landlordid: 36,
    ctids: [62, 71],
  },
  soho: {
    name: "Soho Works",
    icon: sohoworkslogo,
    landlordid: 39,
    ctids: [61, 65],
  },
  arterial: {
    name: "Aterial Group",
    icon: arterialgrouplogo,
    landlordid: 43,
    ctids: [90, 91, 92, 93, 94],
  },
  asc: {
    name: "Ascend Coworking",
    icon: ascendlogo,
    landlordid: 59,
    ctid: [59],
  },
  jsr: {
    name: "The Gherkin",
    icon: gherkinlogo,
    landlordid: 54,
    ctid: [96, 97, 98, 99],
  },
  wnet: {
    name: "Wavenet Internal",
    icon: wavenetlogo,
    landlordid: 42,
    ctids: [82],
  },
};

const NavbarNewLandlordSwitcher = () => {
  const [landlordDetails, setLandlordDetails] = useState(landlordOptions.wsg);
  const selectedLandlord = useSelector((state) => state.landlordswitcher);

  React.useEffect(() => {
    let selectedLandlordDetails = Object.values(landlordOptions).find(
      (landlord) => landlord.landlordid === selectedLandlord.landlordid
    );
    if (selectedLandlordDetails) {
      setLandlordDetails(selectedLandlordDetails);
    }
  }, [selectedLandlord]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      <span className="m-2">Viewing:</span>
      <Dropdown className="me-2 nav-item" align="end">
        <Dropdown.Toggle as="a" className="nav-link nav-flag nav-landlord">
          {landlordDetails.icon && (
            <img
              src={landlordDetails.icon}
              alt={landlordDetails.name + " Logo Icon"}
            />
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ maxHeight: "25vw", overflowY: "auto" }}>
          {Object.keys(landlordOptions).map((landlordKey) => (
            <Dropdown.Item
              key={landlordKey}
              onClick={() => {
                const landlord = landlordOptions[landlordKey];
                dispatch(
                  setLandlord({
                    id: landlord.landlordid,
                    name: landlord.name,
                    logo: landlord.icon,
                    ctids: landlord.ctids,
                  })
                );
                landlord.landlordid === 42
                  ? navigate("/credentials/dot11")
                  : navigate("/dashboard/default");
              }}
            >
              <img
                src={landlordOptions[landlordKey].icon}
                alt="English"
                width="20"
                className="align-middle me-1"
              />
              <span className="align-middle">
                {landlordOptions[landlordKey].name}
              </span>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default NavbarNewLandlordSwitcher;
